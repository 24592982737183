.contentContainer{
  width: 100%;
}
.content{
  max-width: 1400px;
  margin: 0 auto;
}

.cards{
  padding: 20px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}
.card{
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;

  
}
.card img{
  max-width: 200px;
  border-radius: 10px;
  margin-right: 1%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.paginationContainer{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}