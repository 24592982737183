.headerContainer{
  position: fixed;
  background-color: white;

  border-bottom: 1px solid var(--color-border);

  z-index: 100;
}

.header{
  max-width: 1400px;
  margin: 0 auto;

  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;

}

.header .left{
  display: flex;
  align-items: center;
}
.header .left img{
  max-height: 23px;
  margin-right: 20px;
}