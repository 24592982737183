.headContainer {
  position: fixed;
  background-color: white;

  border-bottom: 1px solid var(--color-border);

  z-index: 100;
}

.main {
  background-color: var(--color-fill-1);
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  padding: 20px;

  box-sizing: border-box;
}

.left {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;

  align-items: center;
  justify-content: space-around;
}
.left img{
  border-radius: 12px;
}

.mid {
  background-color: var(--color-fill-2);
  flex-basis: 600px;
  border-radius: 10px;
  padding: 0 20px;

  flex-grow: 1;
}

.mid :global .arco-radio-button-inner {
  padding: 3px 18px;
}

.mid :global .arco-radio-button:after {
  background-color: var(--color-fill-3);
}

.mid :global .arco-radio-button:first-of-type::after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mid :global .arco-radio-button:last-of-type::after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cardImage {
  width: 200px;
}


.legalityContainer {
  display: flex;
}

.mid .legal {
  background-color: rgb(var(--success-6));
  color: white;
}

.mid .restricted {
  background-color: rgb(var(--warning-6));
  color: white;
}

.mid .banned {
  background-color: rgb(var(--danger-6));
  color: white;
}

.mid .not_legal {
  background-color: var(--color-fill-3);
  color: var(--color-text-2);
}

.format {
  width: 80px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 6px;
}

.right {
  display: flex;
  flex-direction: column;
  background-color: var(--color-fill-2);
  border-radius: 10px;
  padding: 0 10px;
  flex-basis: 300px;

  margin-left: 20px;
  overflow: scroll;
  
}

.set {
  height: 32px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 10px;
  margin: 1px 0;
  border-radius:var(--border-radius-small);
}
.setSubText{
  color: var(--color-text-3);
  font-size: 12px;
}

.set:hover{
  background-color: var(--color-fill-3);
  cursor: pointer;
}

.setActive {
  color: rgb(var(--primary-6));
}