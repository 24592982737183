.main{
  height: 100vh;
}
.main :global .arco-layout-header,
.main :global .arco-layout-footer,
.main :global .arco-layout-sider,
.main :global .arco-layout-sider-children,
.main :global .arco-layout-content {
  color: var(--color-white);
  text-align: center;
  font-stretch: condensed;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main :global .arco-layout-header,
.main :global .arco-layout-footer {
  height: 64px;
}

.main :global .arco-layout-content {

}


.content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -20vh;
}
.logo{
  max-height: 68px;
}
.search{
  margin: 30px 0;
}

.main :global .arco-input-inner-wrapper{
  border-radius: var(--border-radius-large) !important;
}